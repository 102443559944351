import Vue from 'vue';
import Router from 'vue-router';
import PTMNavigatorDEMO from "@/components/PTMNavigatorDEMO";


Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE || '/',
  routes: [
    {
      path: '/',
      name: 'PTMNavigatorDEMO',
      component: PTMNavigatorDEMO
    }
  ]
});
