<template>
  <v-app
      id="inspire"
      style="overflow:hidden"
  >
    <router-view />

  </v-app>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
.v-application--wrap {
  background-color: #eee;
}

/*
 * Global DxDataGrid styles
 */
.dx-state-hover {
  cursor: pointer;
}

.dx-pager .dx-page-sizes .dx-selection, .dx-pager .dx-pages .dx-selection {
  background-color: #b0b0b0;
}
</style>